<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-handshake"></i>{{profile.incident_detail.agree_and_close}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p class="notification" v-if="connectedIncidents.length">{{profile.incident_detail.copied_and_open}}<br />
                    <span v-for="(item, index) in connectedIncidents" :key="index" @click="toIncident(item.id_incident)" class="connected-list-item"><i> | </i>{{item.id_incident}}</span>
                </p>
                <br v-if="connectedIncidents && connectedIncidents.length" />
                <div class="notification" v-if="incidentInfo.servicedByRep && incidentInfo.status !== 'fixed_agreed_repaircompany' && incidentInfo.status !== 'returned_to_buildingmanager'">
                    <span>{{profile.incident_detail.confirm_in_progress}}</span>
                </div>
                <p v-html="profile.incident_detail.confirm_close"></p>
                <br />
                <form v-on:submit.prevent="sendReviewRequest()" v-if="!loading">
                    <textarea class="form-control" data-testid="subscriptionText" v-model="fields.descr" :placeholder="profile.incident_detail.reason_agree_and_close_supplier + ' *'" :class="{error: descrError}" @input="validateDescr()"></textarea> 
                    <div class="switch-wrapper switch-wrapper--full" :class="{active: addInvoice}">
                        <div class="inner" @click="addInvoice = !addInvoice">
                            <i class="fa-light fa-file-invoice"></i>
                            <p>{{profile.invoicing.add_invoice}}</p>
                            <span class="label">{{ profile.switches.add_invoice }}</span>
                            <label class="switch">
                                <span class="slider round" data-testid="invoiceAddSlider"></span>
                            </label>
                        </div>      
                    </div>
                    <div v-if="addInvoice" style="margin-top: 20px;">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-field focus">
                                    <label>{{profile.invoicing.invoice_amount}} *</label>
                                    <input type="text" data-testid="invoiceAmount" class="form-control" v-model="fields.amount" @keyup="validateAmount()" @keydown="disallowDot($event)" required>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-field focus">
                                    <label>{{profile.invoicing.invoice_number}} *</label>
                                    <input type="text" data-testid="invoiceNumber" class="form-control" v-model="fields.number" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-field-upload">
                            <input type="file" data-testid="invoiceFile" @change="fileChange" multiple required />
                        </div>
                        <div class="notification notification--error" style="margin-top: 20px;" v-if="descrError">
                            <p><b>{{profile.incident_detail.descr_error}}</b></p>
                        </div>
                    </div>   
                    <button type="submit" data-testid="finishIncidentSubmit" class="button button--cta button--icon"><i class="fas fa-handshake"></i><span>{{profile.incident_detail.agree_close_supplier}}</span></button>
                </form>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            addInvoice: false,
            fields: {
                descr: '',
                amount: '',
                number: '',
                file: null,
            },
            connectedIncidents: [],
            descrError: false,
            uploadError: false,
            loading: false
        }
    },
    props: ['incidentId', 'incidentInfo', 'supplier'],
    created() {
        if (this.incidentInfo.invoice_value_supplier) {
            this.fields.amount = this.incidentInfo.invoice_value_supplier;
        }
        if (this.incidentInfo.invoice_value_supplier) {
            this.fields.number = this.incidentInfo.invoice_number_supplier;
        }
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        fetch('/api/v1/incident/copiedandopen?id_incident=' + this.incidentId)
        .then(response => { response.json().then(
            function(data) {
                self.connectedIncidents = data;
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        finishIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('message', this.fields.descr);
            data.append('status', 'fixed_agreed_repaircompany');
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (self.supplier){
                    let successText = self.profile.dashboard.building_manager.message_table.incident_ID + ' <b>' + self.incidentId + '</b> ' + self.profile.toast.closed_successfully;
                    self.$vToastify.success(successText, self.profile.toast.incident_finished);
                    document.querySelector('body').classList.remove('modal-open');
                    self.$router.push('/supplier');
                } else {
                    self.closeModal();
                }
                    
            });      
        },
        sendReviewRequest() {
            if (!this.fields.descr) {
                this.descrError = true;
            }
            else {
                if (this.fields.file) {
                    this.uploadMedia();
                }  
                else {
                    this.finishIncident();
                }
            }
                
        },
        toIncident(id) {
            this.$router.replace({ path: 'incident-detail', query: { id: id} })
        },
        validateDescr() {
            if (this.fields.descr) {
                this.descrError = false;
            }
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        validateAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
        },
        uploadMedia() {
            this.uploadError = false;
            if (this.fields.file) {
                for (var i = 0; i < this.fields.file.length; i++) {
                    let file = this.fields.file[i];
                    if (file.type === 'application/pdf') {
                        this.loading = true;
                        const data = new FormData();
                        let filename = 'factuur-' + this.incidentId + '.pdf';
                        data.append('file', file);
                        data.append('filename', filename);
                        data.append('id_incident', this.incidentId);
                        data.append('filetype', 'invoice_pdf_supplier');
                        let self = this;
                        fetch('/api/v1/fileupload/uploadincidentpdf', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (!data.url) {
                                self.uploadError = true;
                            }
                            else {
                                self.saveQuotationAmount(data.id_file_upload);
                            }
                        });
                    }
                    else {
                        this.uploadError = true;
                    }
                }
            }
            else {
                this.saveQuotationAmount();
            }
        },
        disallowDot(event) {
            if (event.key === '.' || event.key === 'Decimal') {
                event.preventDefault();
            }
        },
        saveQuotationAmount(fileId) {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            if (this.fields.amount) {
                this.fields.amount = this.fields.amount.replaceAll(',', '.');
            }
            data.append('invoice_value_supplier', this.fields.amount);
            data.append('invoice_number_supplier', this.fields.number);
            data.append('id_file_upload', fileId);
            let self = this;
            fetch('/api/v1/order/addsupplierinvoice', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$store.dispatch('updateInvoiceAdded', true);
                self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                setTimeout(function () {
                    self.finishIncident();
                }.bind(this), 500);
            });
        }
    }
}
</script>
